import React, { Component } from 'react';
import { Link, navigate, graphql } from "gatsby";
import { auth, db, firebase } from '../firebase';
import styled from 'styled-components';
import colors from '../styles/colors';
import { Layout, Header, Heading, Content, MainAction } from '../Components/AppChrome';
import { SearchBox, SearchHits, SearchHit } from '../Components/Search';
import SortableList from '../Components/SortableList';
import { SecondaryButton } from '../Components/Button';

const SubHeading = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${colors.dark};
`;

const SubHeadingText = styled.div`
  width: 100%;
  line-height: 2rem;
  background-color: ${colors.bright};
  margin-right: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
`;

const TrackSwitch = (props) => {
  return (
      <ItemTrackSwitch readOnly={props.readOnly} selected={props.selected} onClick={props.onClick}>{props.children}</ItemTrackSwitch>
  );
}

const ItemAction = styled.div`
  display: flex;
  width: 10rem;
  justify-content: space-evenly;
`;

const ItemTrackSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: initial;
  text-align: center;
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 2px;
  border: ${props => props.readOnly ? '2px solid transparent' : '2px solid white'};
  box-sizing: border-box;
  background-clip: padding-box;
  color: ${props => props.selected ? (props.readOnly ? colors.bright : colors.dark) : colors.bright};
  background-color: ${props => props.selected ? (props.readOnly ? colors.first : colors.accent) : colors.first};
  padding: 1px;
  outline: none;
  cursor: ${props => props.readOnly ? 'default' : 'pointer'};
  margin-left: 0.125rem;
`;

const Info = styled.div`
  padding: 1rem;
  padding-top: 7rem;
`;

const InputContainer = styled.div`
  padding: 0rem 1rem 1rem;
  background-color: ${colors.first};
`;

const Input = styled.input`
  width: 100%;
  line-height: 1.9rem;
  background-color: ${colors.bright};
  font-size: 1rem;
  border-radius: 2px;
  border: 0px solid transparent;
  outline: none;
  color: ${colors.dark};
  margin-right: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &::placeholder {
    color: ${colors.light};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 10rem;
  background-color: white;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 2px;
  border: 2px solid ${colors.first};
  outline: none;
  color: ${colors.dark};
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

  &::placeholder {
    color: ${colors.light};
  }
`;

const InputText = (props) => {
  return (
    <Flex>
      <Input 
        type={"text"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </Flex>
  );
};

const InputTextArea = (props) => {
  return (
    <Flex>
      <TextArea 
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
`;

class ShowPage extends Component {
  constructor(props) {
    super(props);
    this.searchBox = null;

    this.state = {
      authenticating: false,
      authUser: null,
      currentProgram: null,
      exercises: [],
      filter: 'L',
      searchText: '',
      allTemplates: [],
      allPrograms: [],
      programs: [],
      user: null,
      userId: '',
      showingDetails: false,
      exercisesSnapshotUnsubscribe: null,
      addingMessage: false,
      programName: '',
      message: 'Lycka till med träningen!\n\n'
    };

    this.setSearchBoxRef = this.setSearchBoxRef.bind(this);
    this.filterMultiTrack = this.filterMultiTrack.bind(this);
    this.filterShortTrack = this.filterShortTrack.bind(this);
    this.filterStandardTrack = this.filterStandardTrack.bind(this);
  }

  setSearchBoxRef(searchBoxRef) {
    this.searchBox = searchBoxRef;
  }

  onFocusSearchBox() {
    if (this.searchBox) {
      this.searchBox.focus();
    }
  }

  onSignIn = () => {
    auth.doGoogleSignInWithRedirect();
  }

  onAddMessage = () => {
    this.setState({ addingMessage: true }, () => {
      window.scrollTo(0, 0);
    });
  }
  
  onCancelAddMessage = () => {
    this.setState({ addingMessage: false });
  }
  
  onPublishProgram = () => {
    db.publishProgram(this.state.user.id, this.state.currentProgram.originalId, this.state.programName, this.state.message, false);
    this.setState({
        addingMessage: false
    });
    navigate('/?user=' + encodeURIComponent(this.state.user.id));
  }

  onPublishProgramPractitioner = () => {
    db.publishProgram(this.state.user.id, this.state.currentProgram.originalId, this.state.programName, this.state.message, true);
    this.setState({
        addingMessage: false
    });
    navigate('/?user=' + encodeURIComponent(this.state.user.id));
  }

  onShowDetails = event => {
    const programs = this.state.programs.find(program => program.id === event.currentTarget.id) ? this.state.programs : this.state.allPrograms.concat(this.state.allTemplates);

    programs.filter(program => program.id === event.currentTarget.id).forEach(program => {
      const images = this.props.data.allFile.edges;
      const exercisesSnapshotUnsubscribe = db.getExercisesSnapshot(program.userId, program.originalId).onSnapshot(querySnapshot => {
        var exercises = [];
        querySnapshot.forEach(exerciseRef => {
          const exercise = db.mapExercise(exerciseRef);
          const image = images.find(image => image.node.base === exercise.steps[0].image);
          const image2 = images.find(image => exercise.steps.length > 1 && image.node.base === exercise.steps[1].image);
          exercise.index = exercises.length;
          exercise.image = images && exercise.steps[0].image && image ? image.node.childImageSharp.fluid : null;
          exercise.image2 = images && exercise.steps.length > 1 && exercise.steps[1].image && image2 ? image2.node.childImageSharp.fluid : null;
          exercises.push(exercise);
        });
        this.setState({exercises});
      });

      this.setState({
        showingDetails: true,
        filter: 'L',
        currentProgram: program,
        programName: program.name,
        message: program.message || this.state.message,
        exercisesSnapshotUnsubscribe
      });
    });
  }

  onCancelShowDetails = () => {
    if (this.state.exercisesSnapshotUnsubscribe) {
      this.state.exercisesSnapshotUnsubscribe();
    }

    this.setState({
      showingDetails: false,
      exercises: [],
      exercisesSnapshotUnsubscribe: null
    });
  }

  onKeypress = event => {
    switch (event.keyCode) {
      case 13:  //enter
        event.currentTarget.id = this.state.programs.length > 0 ? this.state.programs[0].id : null;
        this.showDetails(event);
        break;
      case 27:  //escape
        this.onCancelShowDetails(event);
        break;
      default:
    }
  }

  onChangeSearchText = event => {
    const searchText = event.target.value;
    var searchTerm = new RegExp(searchText, 'gi');
    const templates = this.state.allTemplates.filter(template => template.name.match(searchTerm) || template.id.toUpperCase() === searchText.toUpperCase());
    const programs = this.state.allPrograms.filter(program => program.name.match(searchTerm) || program.id.toUpperCase() === searchText.toUpperCase());

    this.setState({
      searchText,
      programs: programs.concat(templates)
    });

    window.scrollTo(0, 0);
  }

  onChangeProgramName = event => {
    this.setState({programName: event.target.value});    
  }

  onChangeMessage = event => {
    this.setState({message: event.target.value});    
  }

  componentDidMount() {
    const self = this;
    const queryString = this.getQueryStringParameters(this.props.location.search);
    const userId = queryString.user;
    const programId = Number(queryString.program || 0);
    const templateId = Number(queryString.template || 0);

    self.setState({
      authenticating: true
    });

    firebase.auth.onAuthStateChanged(user => {
      if (!user) {
        return this.redirectToRoot("Not authenticated!");
      }

      self.setState({
        authUser: user,
        authenticating: false,
        showingDetails: programId > 0 || templateId > 0
      });

      db.getUser(userId || user.email)
        .then(user => {
          self.setState({
            user,
            userId: user.id
          });
          
          //navigate('?user=' + encodeURIComponent(user.id));
        })
        .catch(errorMessage => { this.redirectToRoot(errorMessage); });

        db.getPrograms(userId || user.email).then(allPrograms => {
          self.setState({ allPrograms }, () => {
            if (programId > 0) {
              self.onShowDetails({currentTarget: { id: userId + programId } });
            } else {
              self.onChangeSearchText({target: { value: self.state.searchText } });
            }
            console.log("Programs loaded: ", allPrograms.length);
          });
        })
        .catch(errorMessage => { this.redirectToRoot(errorMessage); });

        if (userId !== user.email) {
          db.getPrograms(user.email, true).then(allTemplates => {
            self.setState({ allTemplates }, () => {
              if (templateId > 0) {
                self.onShowDetails({currentTarget: { id: this.state.authUser.email + templateId } });
              } else {
                self.onChangeSearchText({target: { value: self.state.searchText } });
              }
              console.log("Templates loaded: ", allTemplates.length);
            });
          })
          .catch(errorMessage => { this.redirectToRoot(errorMessage); });
        }
    });
  }

  componentWillUnmount() {
    if (this.state.exercisesSnapshotUnsubscribe) {
      this.state.exercisesSnapshotUnsubscribe();
    }
  }

  redirectToRoot(errorMessage) {
    console.log(errorMessage);
    //navigate('/');
    //window.location.href = '/';
  }

  getQueryStringParameters = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
  };

  filterMultiTrack() {
    this.setState({filter: 'S'});
  }

  filterShortTrack() {
    this.setState({filter: 'M'});
  }

  filterStandardTrack() {
    this.setState({filter: 'L'});
  }

  renderAddMessage() {
    return (
      <Layout>
        <Header>
          <Heading backClick={this.onCancelAddMessage}>{ this.state.user ? this.state.user.name : null }</Heading>
          <InputContainer>
            <InputText value={this.state.programName} onChange={this.onChangeProgramName} placeholder={"Namn på programmet"} autoFocus />
          </InputContainer>
        </Header>

        <Content>
          <Info>
            <InputTextArea value={this.state.message} onChange={this.onChangeMessage} placeholder={"Skriv ett meddelande till kunden."} autoFocus />
          </Info>
        </Content>

        { this.state.user.id === this.state.authUser.email ?
          <MainAction>
            <SecondaryButton onClick={this.onPublishProgramPractitioner}>Spara som mall</SecondaryButton>
          </MainAction>
        :
          <MainAction spaceBetween={true}>
            <SecondaryButton onClick={this.onPublishProgramPractitioner}>Till mig</SecondaryButton>
            <SecondaryButton onClick={this.onPublishProgram}>Till kund</SecondaryButton>
          </MainAction>
        
        }
      </Layout>
    );
  }

  renderShowDetails() {
    const programType = this.state.currentProgram && this.state.userId === this.state.currentProgram.userId ? 'program' : 'template';
    const multiTrackFilterEnabled = this.state.exercises.find(exercise => exercise.multiTrack) != null;
    const shortTrackFilterEnabled = this.state.exercises.find(exercise => exercise.shortTrack) != null;
    return (
      <Layout>
        <Header>
          <Heading backClick={this.onCancelShowDetails}>{ this.state.user ? this.state.user.name : null }</Heading>
          <SubHeading>
            <SubHeadingText>
              {this.state.currentProgram ? this.state.currentProgram.name : ''}
            </SubHeadingText>
            <ItemAction>
              {multiTrackFilterEnabled ? 
                <TrackSwitch selected={this.state.filter === 'S'} onClick={this.filterMultiTrack}>S</TrackSwitch>
              :
                <TrackSwitch readOnly={true}>S</TrackSwitch>
              }
              {shortTrackFilterEnabled ? 
                <TrackSwitch selected={this.state.filter === 'M'} onClick={this.filterShortTrack}>M</TrackSwitch>
              :
                <TrackSwitch readOnly={true}>M</TrackSwitch>
              }
              <TrackSwitch selected={this.state.filter === 'L'} onClick={this.filterStandardTrack}>L</TrackSwitch>
            </ItemAction>
          </SubHeading>
        </Header>

        <Content>
          <SortableList items={this.state.exercises.filter(exercise => 
            (this.state.filter === 'S' && exercise.multiTrack)
            || (this.state.filter === 'M' && exercise.shortTrack)
            || (this.state.filter === 'L')
          )} />
        </Content>

        { this.state.currentProgram ?
          <MainAction spaceBetween={true}>
              <Link to={`/new?user=${encodeURIComponent(this.state.userId)}&${programType}=${this.state.currentProgram.originalId}`}>
                <SecondaryButton>Redigera</SecondaryButton>
              </Link>
              <SecondaryButton onClick={this.onAddMessage}>{this.state.currentProgram.sent ? 'Skicka igen' : 'Skicka'}</SecondaryButton>
          </MainAction>
          :
            null
          }
      </Layout>
    );
  }

  renderShowList() {
    return (
      <Layout>
        <Header>
          <Heading backLink={`/?user=${encodeURIComponent(this.state.userId)}`}>{ this.state.user ? this.state.user.name : null }</Heading>
          <SearchBox setSearchBoxRef={this.setSearchBoxRef} value={this.state.searchText} onChange={this.onChangeSearchText} onArrowKeys={this.onKeypress} placeholder={"Sök program"} />
        </Header>

        <Content>
            <SearchHits hits={this.state.programs}>
              <SearchHit onClick={this.onShowDetails} />
            </SearchHits>
        </Content>

        <MainAction>
          <Link to={`/new?user=${encodeURIComponent(this.state.userId)}`}><SecondaryButton>Skapa nytt</SecondaryButton></Link>
        </MainAction>
      </Layout>
    );
  }

  render() {
    if (this.state.addingMessage) {
      return this.renderAddMessage();
    }

    if (this.state.showingDetails) {
      return this.renderShowDetails();
    }

    return this.renderShowList();
  }
}

export default ShowPage;

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          base,
          childImageSharp {
            fluid(maxWidth: 834) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
